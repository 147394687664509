import React from "react";
import NavbarAlt from "./shared/NavbarAlt";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

const scrollContact = () => {
  console.log("test");
  var element = document.getElementById("consulting-contact");
  element.scrollIntoView({ behavior: "smooth" });
};
const ServicesLanding = () => {
  const landingTitle =
    "A seamless consulting experience tailored to your individual needs.";
  const landingSubtitle =
    "We are a team of problem-solvers providing data-driven solutions to businesses. Our pro-bono organization produces quality, purposeful deliverables over a semester-long partnership with our clients.";
  const bottomImage = "/svgs/consulting/landing_bottom.svg";
  return (
    <div className="service-landing">
      <div className="service-landing-container">
        <NavbarAlt />

        <AnimationOnScroll animateIn="animate__fadeIn" delay={300}>
          <h1 className="service-landing-title">{landingTitle}</h1>
          <h4 className="service-landing-subtitle">{landingSubtitle}</h4>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeIn" delay={500}>
          <button className="service-landing-button" onClick={scrollContact}>
            Contact Us
          </button>
        </AnimationOnScroll>
      </div>
    </div>
  );
};

export default ServicesLanding;
